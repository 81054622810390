import { first } from 'lodash'

function reconciliationToolTipGetter(params) {
	const { colDef, data } = params
	const fieldKey = first(colDef.field.split('.'))
	const tooltipContent =
		data[fieldKey]?.validationError || data[fieldKey]?.reconciliationWarning
	return tooltipContent || false
}

export default reconciliationToolTipGetter
